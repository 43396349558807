import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import graphic3 from "../../images/oxygenLogo-w.svg"
import styled from "styled-components"

const Wrapper = styled.section`
  .grid2 {
    grid-template-columns: repeat(2, 1fr);
  }
  .rings {
    max-width: 600px;
  }
  .screens {
    margin-top: 0;
  }
  .link {
    color: #52aabf;
  }
  @media only screen and (max-width: 576px) {
    .user {
      grid-template-columns: repeat(3, 1fr);
      grid-column-gap: 10px;
    }
    .user p {
      font-size: 12px;
    }
    .users h5 {
      font-size: 0.95rem;
    }
    .persona {
      margin-bottom: 0.5rem;
    }
    .areas {
      grid-template-columns: repeat(1, 1fr);
    }
    .screen {
      margin-bottom: 1rem;
    }
  }
`
const SecondPage = () => (
  <Layout>
    <Seo title="Page two" />
    <Wrapper>
      <div className="project-header">
        <StaticImage
          src="../../images/oxygen/oxygen-hero.jpg"
          alt="Project"
          placeholder="dominantColor"
          className="hero"
        />
        <div className="project-title">
          <div className="client">
            <img className="client-logo" alt="logo" src={graphic3} />
            <p>/ creative direction</p>
          </div>
          <div className="desc">
            <h2>Brand Architecture + Creative Leadership</h2>
            <p>
              leading an evolution of brand expression and developing scalable
              design systems
            </p>
          </div>
        </div>
      </div>
      <div className="highlights">
        <h5>Project Scope:</h5>
        <ul className="scope">
          <li>Brand Expression</li>
          <li>Design System</li>
          <li>Web, Branded Content + Marketing</li>
        </ul>
      </div>
      <div className="highlights">
        <h5>Overview:</h5>
        <p>
          Oxygen helps enterprise and mid-sized companies drive growth through
          sales enablement and immersive workplace learning programs. As
          Creative Director, I manage Oxygen’s design studio and drive brand
          evolution as the business shifts and grows. The latest brand iteration
          is rooted in Oxygen’s core values:
        </p>
      </div>
      <StaticImage
        src="../../images/oxygen/principle-rings.png"
        alt="Project"
        placeholder="blurred"
        className="rings"
      />
      <div className="highlights">
        <h5>Project Goals:</h5>
        <ul>
          <li>
            Develop a fresh, modern, and scalable design system for Oxygen
          </li>
          <li>Drive messaging framework and brand narrative</li>
          <li>
            Design all-new identity system, including variations for sub-branded
            services
          </li>
          <li>
            Create new interactive branded content, sales tools and marketing
            templates
          </li>
          <li>Launch all new Oxygen website and brand experience toolset</li>
        </ul>
      </div>
      <div className="highlights">
        <h5>My Role:</h5>
        <ul>
          <li>
            Facilitate discovery sessions with company leaders on project goals
            and requirements
          </li>
          <li>
            Lead the development of brand identity concepts based on executive
            leadership inputs
          </li>
          <li>
            Create scalable brand systems and maketing templates that
            accelerated sales activities
          </li>
          <li>Build style guide documentation and art asset libraries</li>
          <li>
            Creative direct photo shoots, video shoots, and marketing
            communication
          </li>
          <li>
            Manage the Oxygen Studio staff + marketing and web development
            vendors
          </li>
        </ul>
      </div>
      <br />
      <div className="screens">
        <div className="project-label">
          <p>Brand Design Language Development</p>
        </div>
        <StaticImage
          src="../../images/oxygen/oxy-3.png"
          alt="Project"
          placeholder="dominantColor"
          quality={100}
          className="screen"
        />
        <StaticImage
          src="../../images/oxygen/oxy-4.png"
          alt="Project"
          placeholder="dominantColor"
          className="screen"
          quality={100}
        />
        <StaticImage
          src="../../images/oxygen/oxy-2.png"
          alt="Project"
          placeholder="dominantColor"
          className="work"
          quality={100}
        />
        <div className="project-label">
          <p>
            Website{" "}
            <a className="link" href="https://oxygenexp.com">
              www.oxygenexp.com
            </a>
          </p>
        </div>

        <div class="embed-container">
          <iframe
            src="https://player.vimeo.com/video/908788638"
            frameborder="0"
            webkitAllowFullScreen
            mozallowfullscreen
            allowFullScreen
          ></iframe>
        </div>
        <StaticImage
          src="../../images/oxygen/oxy-1.png"
          alt="Project"
          placeholder="dominantColor"
          className="work"
          quality={100}
        />
        <br />
        <br />
        <br />
        <StaticImage
          src="../../images/oxygen/oxygen-4.jpg"
          alt="Project"
          placeholder="dominantColor"
          className="work"
          quality={100}
        />
      </div>
      <div className="footer-nav">
        <Link to="/">&#x2190; Recent work</Link>
        <span></span>
        <Link to="/work/hme-gamify">Next Project &#8594; </Link>
      </div>
    </Wrapper>
  </Layout>
)

export default SecondPage
